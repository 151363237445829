@import '../modules/colors', '../modules/shadows', '../modules/fonts', '../modules/containers',
	'../modules/breakpoints';

.container {
	width: 100%;
	border: 1px solid $bg-grey;
	background-color: $brand-navy;
	color: #fff;
	transition: 0.3s;
	font-size: 0.8rem;
	@media screen and (max-width: $maxTablet) {
		font-size: 0.7rem;
	}
	cursor: pointer;
	h3 {
		margin: 0;
		margin-right: 10px;
	}
	h3,
	span,
	p,
	a {
		margin-left: 15px;
	}
	p {
		font-weight: 500;
	}
}

.read {
	@extend .container;
	background-color: $bg-grey;
	color: $dark-grey;
}
