@import '../modules/colors', '../modules/shadows', '../modules/fonts', '../modules/containers',
	'../modules/breakpoints';

.input {
	height: 3rem;
	background: $bg-grey;
	border-radius: 2rem;
	border: solid 2px $shadow-grey;
	padding: 0 1.75rem;
	font-size: 1.1rem;
	font-weight: 600;
	font-family: $baloo;
	margin: 0 1.5rem;
	box-shadow: none !important;
	@media screen and (max-width: $maxLandscape) {
		height: 2.5rem;
	}
	@media screen and (max-width: $maxTablet) {
		font-size: 1rem;
		margin: 0 10px;
		padding: 0 15px;
	}
	&:active,
	&:focus {
		border: solid 2px $brand-yellow;
	}
	@include hide-mobile();
}
