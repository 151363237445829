$maxSmallPhone: 400px;
$maxPhone: 600px;
$minTablet: 601px;
$maxTablet: 768px;
$maxLandscape: 992px;
$extraLargeScreen: 1200px;

@mixin hide-mobile() {
	@media screen and (max-width: $maxPhone) {
		display: none !important;
	}
}

@mixin show-mobile-only() {
	@media screen and (min-width: $minTablet) {
		display: none !important;
	}
}
