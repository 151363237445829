@import '../modules/colors', '../modules/shadows', '../modules/fonts', '../modules/containers',
	'../modules/breakpoints';

.container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	@include header-text(3rem);
	@include box-shadow();
	height: 4rem;
	@media screen and (max-width: $maxPhone) {
		height: 3rem;
	}
	padding: 0 2rem;
	a {
		&:hover {
			color: $brand-yellow;
		}
		font-size: 2.5rem;
		@media screen and (max-width: $maxLandscape) {
			font-size: 2rem;
		}
		@media screen and (max-width: $maxTablet) {
			font-size: 1.25rem;
		}
		@media screen and (max-width: $maxPhone) {
			font-size: 1.8rem;
		}
	}
}

.content {
	@include basic-flex();
}

.alert {
	@include basic-flex();
	width: 100%;
	background-color: $error;
	color: #fff;
	padding: 10px;
	transition: 0.3s;
	svg {
		margin-right: 10px;
	}
	&:hover {
		background-color: $brandNavy;
	}
}

.menuItemIcon {
	color: $brand-navy;
}

.menuItemText {
	font-family: $baloo;
	font-weight: 600;
	color: $brandNavy;
}
