@import '../modules/colors', '../modules/fonts', '../modules/shadows', '../modules/containers',
	'../modules/breakpoints';

.container {
	box-shadow: 0 0 10px $dark-grey;
	min-height: max-content;
	background: $brandNavy;
	padding: 15px 50px 50px;
	font-family: $baloo;
	@media screen and (max-width: $maxTablet) {
		padding: 10px 0 40px 0;
	}
}

.content {
	max-width: 1100px;
	margin: auto;
	@include basic-flex();
	justify-content: space-between;
	color: #fff;
	@media screen and (max-width: $maxTablet) {
		flex-direction: column;
		align-items: center;
		text-align: center;
	}
}

.logo {
	@include header-text(2rem);
	color: #fff;
	align-self: start;
}

.socials {
	@include basic-flex();
	justify-content: space-between;
	padding: 10px 50px 25px 0;
	a {
		&:hover {
			color: $brandYellow;
		}
	}
	@media screen and (max-width: $maxTablet) {
		padding: 10px 0;
	}
}

.fine {
	color: #fff;
}

.links {
	@include basic-flex();
	flex-wrap: wrap;
	justify-content: space-between;
	font-weight: 700;
	width: 250px;

	@media screen and (max-width: $maxTablet) {
		margin: 30px 0 0 0;
	}
}

.link {
	@include basic-flex();
	border-radius: 5px;
	color: $brandNavy;
	padding: 5px;
	background: $backgroundGrey;
	width: 40%;
	margin-bottom: 20px;
	svg {
		margin-right: 5px;
	}
	&:hover {
		margin-top: -5px;
		margin-bottom: 25px;
		box-shadow: 0 5px $brandYellow;
	}
}

.contact {
	align-self: start;
	h4 {
		margin-top: 10px;
	}

	@media screen and (max-width: $maxTablet) {
		align-self: center;
	}
}

.contactLink {
	@include basic-flex();
	margin-top: 10px;
	svg {
		transition: 0.3s;
		margin-right: 10px;
	}
	&:hover {
		svg {
			color: $brandYellow;
		}
	}
}
