@import '../modules/colors', '../modules/shadows', '../modules/fonts', '../modules/containers',
	'../modules/breakpoints';

@keyframes float {
	0% {
		top: 0;
	}
	50% {
		top: 2px;
	}
	100% {
		top: 0;
	}
}

.container {
	@include basic-flex();
}

.menuToggle {
	color: $brandNavy;
	font-size: 2.5rem !important;
	@include show-mobile-only();
}

.link {
	@include center-child();
	margin: 0 1rem;
	width: 3rem;
	height: 3rem;
	transition: 0.3s;
	@media screen and (max-width: $maxLandscape) {
		width: 2.5rem;
		height: 2.5rem;
		margin: 0 0.5rem;
	}
	border-radius: 50%;
	background: $backgroundGrey;
	@include hide-mobile();
	svg {
		width: 100%;
		height: 60%;
		color: $brandNavy;
	}
	&:hover {
		background-color: $shadowGrey;
		cursor: pointer !important;
	}
}

.notification {
	position: absolute;
	top: 0;
	margin-left: -25px;
	font-size: 20px;
	animation-name: float;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}
